import { Dispatch } from 'redux'
import { Action } from '../redux/actions/actions'
import { getJwt } from '../redux/actions/authentication'
import { showToastError, throwErrorMessage, throwErrorMessageImport, throwSuccessMessage } from './errors-utils'
import { ISortOptions } from './table-types'
import { IPagination } from '../hooks/usePagination'
import { GatewayEntity } from '../entity/system-settings'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export function onlyFetchBuilder(uri: string, scheme?: IIndexable<boolean>): (params: IIndexable) => Promise<any> {
  return (params: IIndexable) => {
    const url = new URL(uri, document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    if (scheme) {
      for (const queryParam in scheme) {
        if (queryParam === 'Symbol') {
          url.searchParams.set(queryParam, params[queryParam].value)
        } else if (queryParam === 'Search') {
          if (params[queryParam]) {
            url.searchParams.set(queryParam, params[queryParam])
          }
        } else if (queryParam === 'Lp' || queryParam === 'lp') {
          const find = new RegExp('%20', 'g')
          url.searchParams.set(queryParam, params[queryParam]?.replace(find, ' '))
        } else {
          url.searchParams.set(queryParam, params[queryParam])
        }
      }
    }

    if (params.field && params.field.length && params.by !== 'none') {
      url.searchParams.set('sort', params.field)
      url.searchParams.set('by', params.by)
    }

    return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
  }
}

type OnlyFetchFunc = ReturnType<typeof onlyFetchBuilder>

export function fetchBuilder(fetchCallback: OnlyFetchFunc, action: Action | string) {
  return (params: any) => {
    return (dispatch: Dispatch) => {
      dispatch({ type: Action.InProgressStart })
      if (typeof params.setLoading === 'function') {
        params.setLoading(true)
      }
      return fetchCallback(params)
        .then((data: any) => {
          if (data.Status === 1) {
            throwErrorMessage(data?.Description, true)
          } else {
            if (data[0]?.TickSize) {
              downloadFileJson(`SyntheticIndex`, JSON.stringify(data, null, 2))
            } else if (data[0]?.Children) {
              downloadFileJson(`SyntheticSymbols`, JSON.stringify(data, null, 2))
            } else {
              dispatch({ type: action, data })
            }
          }
        })
        .catch((error: Error) => processError(error, dispatch))
        .finally(() => {
          dispatch({ type: Action.InProgressEnd })
          if (typeof params.setLoading === 'function') {
            params.setLoading(false)
          }
        })
    }
  }
}

export function modifyBuilder(uri: string, fetchCallback: any, reduxAction: any, scheme?: any, errorHandler?: any) {
  return (arg: any) => {
    const { action, body, params } = arg
    return (dispatch: Dispatch) => {
      const findLengthChr = uri.split('').filter(item => item === '/').length
      let url =
        findLengthChr < 3
          ? new URL(`${uri}/${action === 'clone' ? 'add' : action}`, document.location.origin)
          : new URL(`${uri}${action === 'clone' ? 'Add' : `${action[0]?.toUpperCase()}${action?.slice(1)}`}`, document.location.origin)

      if (action === '') {
        url = new URL(uri, document.location.origin)
      }
      url.searchParams.set('gateway', params.Gateway)
      if (params.id) {
        url.searchParams.set('id', params.id)
      }

      if (params.Lp) {
        const find = new RegExp('%20', 'g')
        url.searchParams.set('Lp', params.Lp.replace(find, ' '))
      }

      if (params.lp) {
        const find = new RegExp('%20', 'g')
        url.searchParams.set('lp', params.lp.replace(find, ' '))
      }

      if (scheme) {
        for (const queryParam in scheme) {
          url.searchParams.set(queryParam, params[queryParam])
        }
      }
      dispatch({ type: Action.InProgressStart })
      if (typeof params.setLoading === 'function') {
        params.setLoading(true)
      }

      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
        body: body ? JSON.stringify(body) : undefined,
      })
        .then((response: Response) => response.json())
        .then((result: any) => {
          if (result.Message) {
            dispatch({ type: Action.SyntheticIndexPreviewDefault })
            dispatch({ type: Action.SyntheticSymbolsPreviewDefault })
            throwErrorMessage(result.Message, true)
          } else {
            if (result.Status) {
              if (result?.Errors) {
                throwErrorMessageImport(result?.Errors.join(', '))
              } else if (errorHandler) {
                return errorHandler(result.Status)
              } else {
                return new Promise((resolve, reject) => reject(result.Description ? showToastError('api.error.delete-errors', result) : new Error(`${result.Status}`)))
              }
            } else if (result.Status === undefined) {
              dispatch({ type: reduxAction, data: result })
            } else {
              throwSuccessMessage('Successfully')
              if (result?.TickSize) {
                dispatch({ type: reduxAction, data: result })
              } else {
                return fetchCallback(params)
              }
            }
          }
        })
        .then((obj: any) => {
          if (obj) {
            dispatch({ type: reduxAction, data: obj })
          }
        })
        .catch((error: Error) => processError(error, dispatch))
        .finally(() => {
          dispatch({ type: Action.InProgressEnd })
          if (typeof params.setLoading === 'function') {
            params.setLoading(false)
          }
        })
    }
  }
}

export function checkResponse(response: any, dispatch?: any) {
  if (response.ok) {
    return response.json()
  }

  return new Promise((resolve, reject) => reject(new Error(response?.status?.toString())))
}

export function checkTextResponse(response: Response) {
  if (response.ok) {
    return response.blob()
  }
  return new Promise((resolve, reject) => reject(new Error(response.status.toString())))
}

export function checkResponseHeader(response: Response) {
  if (response.ok) {
    const header = response.headers.get('Content-Disposition')
    const parts = header!.split(';')

    return parts[1].split('=')[1]
  }
  return new Promise((resolve, reject) => reject(new Error(response.status.toString())))
}

export function checkTextResponseAxios(response: any) {
  if (response.data.Status === 0) {
    throwSuccessMessage('Successfully')
    return response
  } else if (response.data.Description) {
    throwErrorMessageImport(response.data.Description)
  } else {
    throwErrorMessageImport(response?.data?.Errors.join(', '))
  }
}

export function processError(error: any, dispatch: Dispatch): void {
  if (error?.message === 'unauthorized') {
    dispatch({ type: Action.Unauthorized })
  }

  if (error.name === 'AbortError' || error.name === 'CanceledError') return
  if (error?.message === '401') {
  } else if (error?.message.includes('Cannot read properties of undefined')) {
  } else if (error?.Errors) {
    throwErrorMessage(error?.Errors)
  } else {
    throwErrorMessage(error?.message)
  }
}

export function buildHTTPPostOptions(bodyObject: any): RequestInit {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${getJwt()}`,
    },
    body: bodyObject ? JSON.stringify(bodyObject) : undefined,
  }
}

export function buildHTTPGetOptions(signal?: any): RequestInit {
  return {
    method: 'GET',
    signal,
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }
}

export function downloadFileAnyType(fileName: string, data: any, type: string): void {
  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([data], { type: type })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFile(fileName: string, data: any): void {
  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([data], { type: 'text/plain' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFilePdf(fileName: string, data: any): void {
  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([data], { type: 'application/pdf' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFileJson(fileName: string, data: any): void {
  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([data], { type: 'application/json' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFileCVS(fileName: string, data: any): void {
  data[0].Version = 1
  const keys = Object.keys(data[0]).slice(0, 4)
  keys.push('Version')
  const contractSize = keys.map((item: any) => {
    if (item === 'ContractMultiplier') {
      item = 'ContractSize'
      return item
    } else if (item === 'Symbol') {
      item = 'TpSymbol'
      return item
    } else {
      return item
    }
  })

  let result = contractSize.join(';') + '\n'

  data.forEach(function (obj: any) {
    result += keys.map(k => obj[k]).join(';') + '\n'
  })

  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([result], { type: 'text/csv' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFileFeedPlatformExel(fileName: string, exelData: any): void {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const newDate = exelData.map((el: any) => {
    const s = el.Symbols.map((item: any) => item.value).join(',')
    return {
      Id: el.Id,
      Symbols: s,
      MaxLevels: el.MaxLevels,
      MarkupType: el.MarkupType,
      MarkupBid: el.MarkupBid,
      MarkupAsk: el.MarkupAsk,
      MinSpread: el.MinSpread,
      MaxSpread: el.MaxSpread,
      Multiplier: el.Multiplier,
      Rounding: el.Rounding,
      SpreadBalance: el.SpreadBalance,
      SoftFilterPercent: el.SoftFilterPercent,
      SoftFilterCount: el.SoftFilterCount,
      HardFilterPercent: el.HardFilterPercent,
      HardFilterCount: el.HardFilterCount,
      LimitationPercent: el.LimitationPercent,
      FilterInvalidQuotes: String(el.FilterInvalidQuotes),
      DeliveryToPlatformDisabled: String(el.DeliveryToPlatformDisabled),
    }
  })

  newDate[0].Version = 5

  const ws = XLSX.utils.json_to_sheet(newDate)
  const wb = { Sheets: { [`feedPlatformRules`]: ws }, SheetNames: [`feedPlatformRules`] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

export interface IIndexable<T = any> {
  [key: string]: T
}

export function buildURL(path: string, ...queryParams: IIndexable[]): string {
  const url = new URL(path, document.location.origin)

  for (const queryParamsObject of queryParams) {
    for (const param of Object.keys(queryParamsObject)) {
      if (Array.isArray(queryParamsObject[param])) {
        for (const paramItem of queryParamsObject[param]) {
          url.searchParams.append(param, paramItem)
        }
      } else {
        if (param === 'hbbookName' || param === 'lpName' || param === 'lp') {
          const find = new RegExp('%20', 'g')
          url.searchParams.set(param, queryParamsObject[param].replace(find, ' '))
        } else {
          url.searchParams.set(param, queryParamsObject[param])
        }
      }
    }
  }

  return url.toString()
}

export async function fetchGet(path: string, ...queryParams: any): Promise<any> {
  const signal: any = queryParams[queryParams?.length - 1]

  try {
    let response
    if (queryParams.length && 'aborted' in signal) {
      response = await fetch(buildURL(path, ...queryParams), buildHTTPGetOptions(signal))
    } else {
      response = await fetch(buildURL(path, ...queryParams), buildHTTPGetOptions())
    }

    return await checkResponse(response)
  } catch (e) {
    throw e
  }
}

export async function fetchPost(path: string, body: any, ...queryParams: IIndexable[]): Promise<any> {
  try {
    const response = await fetch(buildURL(path, ...queryParams), buildHTTPPostOptions(body))
    return await checkResponse(response)
  } catch (e) {
    throw e
  }
}

export function transformSortOptions(params: ISortOptions): IIndexable {
  if (params.field.length && params.by !== 'none') {
    return {
      sort: params.field,
      by: params.by,
    }
  }
  return {}
}

export function transformPagination(pagination: IPagination): IIndexable {
  return {
    page: pagination.Page,
    count: pagination.Count,
  }
}

export function transformGateway(gateway: GatewayEntity): IIndexable {
  return {
    gateway: gateway.Name,
  }
}

export function wsSchema(): string {
  return window.location.protocol === 'https:' ? 'wss' : 'ws'
}
